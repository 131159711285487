/* Variables */
$b:  15px;

$pink:  #FF3D5A;
$green: yellowgreen;
$blue: #4FAAE2;
$code: #cbe1ea;

$max-width: 800px;

@mixin header-type($spacing, $bold) {
  font-family: 'Roboto Mono', monospace;
  letter-spacing: $spacing;
  font-weight: $bold;
}

@mixin side-bar-anim($color) {
  position: relative;

   &:before {
      content: " ";
      display: inline-block;
      width: 5px;
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      background: $color;
      transition: all 350ms;
      z-index: -1;

      @media screen and (min-width:650px) {
        width: 8px;
      }
    }
}

@mixin side-bar($color) {
  position: relative;
  border-left-style:solid;
  border-left-width: 5px;
  border-left-color: $color;

  @media screen and (min-width:650px) {
    border-left-width: 8px;
  }

}



/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */


li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}

.skills {

  .longform & ul {
    padding-left: 8px;
    font-size: 0.8em;
    columns: 1;
  }

  .longform & ul > li:before {
    top: 7px;
  }

  .longform & ul > li {
    padding-left: 15px;
  }

  @media screen and (min-width: 400px){
    .longform & ul {
      columns: 2;
    }
  }

  @media screen and (min-width: 1100px){
    margin-left: 800px;
    padding: 0px $b;
    max-width: calc(73em - 800px);
    position: absolute;

    .longform & ul {
      columns: 1;
    }
  }

  @media screen and (min-width: 1150px){
    .longform & ul {
      columns: 2;
    }
  }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: $pink;
    }

    &:hover {
        color: $pink;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
* CALLOUT
*/
.callout {
  background: $code;
  max-width: $max-width;
  padding: $b;
}

/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $code;
    border-radius: 3px;
    background-color: #f8fdff;
    max-width: $max-width;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}




//GENERAL
* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  max-width: 73em;
  margin: 0 auto;
  font-size: 1.1em;
  padding: 0 $b/2;

  @media screen and (min-width: 550px) {
    padding: 0 $b;
  }
}

h1, h2, h3, h4, h5 {
  @include header-type(0px, bold);
  line-height: 1.2;

  & + p {
    margin-top: $b;
  }
}

h5 {
  font-size: 0.95em;
  margin-top: 0.75em;
}


p, ol, ul {
  line-height: 1.5;
  font-size: 1em;
  color: #333;
}

.small {

}

a {
  color: $pink;
  transition: 350ms all;
}

.link-dark a, a.link-dark,
.link-dark a:visited, a.link-dark:visited {
  color: darken($blue, 20%);
}

//HEADER
.site-header {
  padding: $b;
  position: relative;
  margin-bottom: $b*2;

  @media screen and (min-width: 650px) {
    margin-bottom: $b*3;
    padding: $b*2 $b;
    display: flex;
    justify-content: space-between;
  }
}

.site-title:hover {
  text-decoration: none;
}

.logo {
  // text-transform: lowercase;
  word-spacing: 0;
  letter-spacing: 3px;
  font-size: 1.5em;
  text-decoration: none;
  color: black;
  position: relative;
  transition: 300ms all;
  display: inline-block;

  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-left: 3px;
    animation: colorChange 6s infinite;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 15px;
    bottom: 5px;
    left: 100%;
    transition: 350ms all;
    animation: colorChange 6s infinite;
    z-index: -1;
  }

  &:hover {
    color: white;
  }

  &:hover:before {
    left:0%;
    top:0;
    bottom:0;
  }

}

.main-menu {
  @include header-type(3px, normal);
  margin:0;
  font-size: 0.85rem;

  @media screen and (min-width:650px) {
    font-size: 1rem;
  }

  ul {
    padding:0;
  }

  li {
    list-style: none;
    margin-bottom: 5px;

    @media screen and (min-width:415px) {
      &:first-child {
        margin-left:0;
      }

      margin-left: $b;
      display: inline-block;
    }

  }

  .active a:before {
    background: $pink;
  }

  a {
    color: black;
    text-decoration: none;
    display: block;
    padding:$b/3 $b/2 $b/3 $b;
    position: relative;

   @include side-bar-anim(black);

    &:hover {
      color: white;
    }

    &:hover:before {
      width: 100%;
    }

  }

}

.post-footer {
  margin-top: 4em;
  padding-top: 1em;
}


//FOOTER
.main-footer {
  text-align: center;
  @include header-type(3px, normal);
  margin-top: 8em;
  font-size: 0.85em;

  p {
    margin: 0.5em;
  }
}






//HOMEPAGE
.home-intro {
    padding: 0 $b;
    margin-bottom: 4em;

   @media screen and (min-width: 550px) {
     display: flex;
     margin-bottom: 4em;
    }

}

.view-catgory {
  span {
    display: block;

    @media screen and (min-width: 550px) {
      display: inline;
    }
  }
}

//Personal Image with Orbiting Dots
.orbit {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding:0;
  height: 225px;
  width: 225px;
  text-align: center;
  flex: 0 0 auto;

  &.col {
    margin-bottom: 4em;
    padding:0;

    @media screen and (min-width: 550px) {
      margin-bottom: 3em;
    }
  }


  img {
    border-radius: 100%;
    max-width: 100%;
    display: block;
    filter: grayscale(100%);
    position: relative;
    z-index:1;
  }

  div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
  }

  .orbit-1 {
    background: $pink;
    animation: orbit1 6s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
  }

  .orbit-2 {
    background: $blue;
    animation: orbit2 7s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
  }

  .orbit-3 {
    background: $green;
    animation: orbit3 8s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
  }

}


//List home pagepage with titles and descriptions
.separated-list {
  width: 100%;
  margin:0;
  padding:0;
  align-self: center;

  li {
    list-style: none;
    line-height: 1.5;
    margin-bottom: 1.5em;
  }

  span {
    @include header-type(0px, bold);
    display: block;
    font-size: 1.1em;


  }

  @media screen and (min-width: 550px) {
    width: 75%;
    padding-left: 25px;

  }

  @media screen and (min-width: 750px) {

    li {
        position: relative;
        padding-left: 250px;
        margin-bottom: 1em;
    }

    span {
        position: absolute;
        left:0;
        text-align: right;
        padding-right: $b*2;
        display: inline-block;
        width: 255px;
        transform: translateY(-1px);
        line-height: 1.35;
    }

  }
}



//Post Items
.read-more {
  @include header-type(3px, bold);
}

.post-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: $b*2;

  & > .post-item {
    width: 100%;
    padding: 0 $b;

    @media screen and (min-width: 550px) {
        width: 50%;
    }

    @media screen and (min-width: 800px) {
        width: 33.33333%;
    }

    @media screen and (min-width: 1050px) {
        width: 25%;
    }
  }
}

.post-item {
  width: 100%;
  display: flex;
  margin-bottom: 2em;

  & > a {
    width: 100%;
  }

  h3 {
    margin-bottom: 0.75em;
    line-height: 1.2;
  }

  .meta {
      margin-bottom: 1em;
  }

  a, a:visited, a:active {
    text-decoration: none;
    color: black;
    display: flex;
  }

  img {
    width:100%;
    height: auto;
    display: block;
    border:0;
  }

  &__image {
    margin: (-$b) (-$b) $b (-$b);
    clip-path: polygon(100% 0, 100% 92%, 0 96%, 0 0);
    height: 0;
    padding-bottom: 50%;
    overflow: hidden;
  }

  .read-more {
    position: absolute;
    left: $b;
    bottom: 0;
    color: white;
    padding: $b 0 0 0;
    transition: all 350ms;
    display: inline-block;
    letter-spacing: 3px;
    transform: rotate(-3deg);


    &:after {
      content: " >>>";
      transition: all 350ms;
    }
  }
}

.post-section {
  width: 100%;
  padding: 0 $b;
  margin-bottom: 2em;

  h2, h3 {
    margin-bottom: 0;
  }

  .meta {
    margin-bottom: 1em;
  }

  a, a:visited, a:active {
    text-decoration: none;
    color: black;
  }

  img {
    display: block;
    border:0;
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;
  }



  .read-more {
    color: white;
    padding: $b 0 0 0;
    transition: all 350ms;
    display: inline-block;
    letter-spacing: 2px;
    transform: rotate(-3deg);

    &:after {
      content: " >>>";
      transition: all 350ms;
    }
  }

  &:nth-child(3n+1) {
    .inner {
      border-top: 8px solid $pink;
    }

    .read-more {
      color: $pink;
    }
  }

  &:nth-child(3n+2) {
    .inner {
      border-top: 8px solid $blue;
    }

    .read-more {
      color: $blue;
    }
  }

  &:nth-child(3n+3) {
    .inner {
      border-top: 8px solid $green;
    }

    .read-more {
      color: $green;
    }
  }

  &__image {
    margin-bottom: $b;
  }

  @media screen and (min-width: 550px) {
    .inner {
      display: flex;
    }

    &__image {
      width: 50%;
      max-width: 100%;
      flex: 0 0 50%;
      margin-right: $b;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 750px) {
    &__image {
      width: 33%;
      flex: 0 0 33%;
    }
  }


}

.post-research .read-more, a.post-research {
  color: $pink;
}

.post-tech .read-more, a.post-tech {
  color: $blue;
}

.post-personal .read-more, a.post-personal {
  color: $green;
}

.post-research {
  .inner {
    border-top: 8px solid $pink;
  }
}

.post-tech {
  .inner {
    border-top: 8px solid $blue;
  }
}

.post-personal {
  .inner {
    border-top: 8px solid $green;
  }
}


.post-item .inner, .post-section .inner {
  padding: $b;
  padding-bottom: $b*3;
  box-shadow: 0px 1px 5px rgba(20, 20, 20, 0.2);
  flex:1;
  position: relative;
  overflow: hidden;
  transition: all 350ms;
}

.post-item a:hover .inner {
  box-shadow: 0px 1px 6px rgba(20, 20, 20, 0.3);
  transform: translateY(-2px);

  .read-more:after {
    letter-spacing: 5px;
  }
}

.read-more:hover:after {
  letter-spacing: 5px;
}


.meta {
  font-size: 0.75em;
  margin:0;
  line-height: 1.5;
  @include header-type(3px, bold);
  display: block;
}



.longform {

  header {
    margin-bottom: 1em;

    @media screen and (min-width: 550px) {
      margin-bottom: 2em;
    }
  }

  h3, h5, h6 {
    max-width: $max-width;
  }

  h1, h2, h4 {
    padding-left: 0.25em;
    max-width: $max-width;

    @include side-bar(black);
  }

    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6,
    blockquote + h1,
    blockquote + h2,
    blockquote + h3,
    blockquote + h4,
    blockquote + h5,
    blockquote + h6,
    .iframe + h1,
    .iframe + h2,
    .iframe + h3,
    .iframe + h4,
    .iframe + h5,
    .iframe + h6,
    .callout + h1,
    .callout + h2,
    .callout + h3,
    .callout + h4,
    .callout + h5,
    .callout + h6{
      margin-top: 45px;
    }


  p > img + em, .caption {
    text-align: center;
    font-size: 0.8em;
    display: block;
  }

  p, ol, ul {
    font-size: 1.1em;
    line-height: 1.5;
    margin-bottom: 1em;
    max-width: $max-width;

    @media screen and (min-width: 550px) {
    }
  }

  ul, ol {
    padding-left: $b*3;
    margin-bottom: 2em;
    & > li {
      padding-left: $b*2;
      margin-bottom: 0.75rem;
    }

    ul, ol {
      margin-bottom: 0;
    }
  }

  ul > li {
    list-style: none;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: $pink;
      position: absolute;
      right: 100%;
      top: 8px;

      @media screen and (min-width: 550px) {
        top: 12px;
      }
    }
  }


  ol > li {
    list-style: none;
    position: relative;
    counter-increment: ol-counter;

    &:before {
      content: counter(ol-counter);
      display: block;
      color: $pink;
      position: absolute;
      right: 100%;
      top: 0;
      @include header-type(0, bold);
    }
  }

  .intro {
    font-size: 1em;
    letter-spacing: 1px;
    line-height: 2.25em;

    @media screen and (min-width: 550px) {
      font-size: 1.25em;
    }
  }

  blockquote {
    padding-left: 20px;
    font-size: 1.1em;
    margin-left: $b;

    @include side-bar(black);

    @media screen and (min-width: 550px) {
      margin-left: $b*3;
      max-width: $max-width - ($b*3);
    }
  }

  p > img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    padding: $b/2;
    padding-bottom: 0;

    @media screen and (min-width: 550px) {
      padding: $b;
      padding-bottom: 0;
    }
  }

  .meta.caption {
    color: #888;
    font-size: 0.85rem;
    text-align: center;
    display: block;
    font-weight: normal;
  }
}


.longform .meta {
  font-size: 1rem;
  text-transform: capitalize;
  display: inline-block;
}

.tag a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.longform.post-research {
  .tag a {
    color: $pink;
  }

  h1 {
    border-color: $pink;
  }

}

.longform.post-tech{
  .tag a {
    color: $blue;
  }

  h1 {
    border-color: $blue;
  }
}

.longform.post-personal {
  .tag a {
    color: $green;
  }

  h1 {
    border-color: $green;
  }

}

.col {
  // padding: 0 $b/2;

  // @media screen and (min-width: 550px) {
    padding: 0 $b;
  // }
}


.layout-split-2 {

  & > img {
    padding: $b/2;
    max-width: 100%;
    height:auto;
    display: block;
    margin: 0 auto;

    @media screen and (min-width: 550px) {
      padding: $b;
    }
  }


  @media screen and (min-width: 700px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    & > img {
      vertical-align: bottom;
      width: 50%;
      flex: 0 0 50%;
    }

    .col {
      width: 50%;
    }
  }
}

a.reset {
  color: black;
}

.fluid-width-video-wrapper {
  margin: $b 0;

  iframe {

  }
}

.filter-posts .mix{
    display: none;
}




// POST PAGE
.iframe {
  max-width: $max-width;
}

.about-author {
  max-width: $max-width;

  padding: $b;
  background: $code;
  overflow: visible;
  margin-bottom: 3em;

  @media screen and (min-width: 550px){
    display: flex;
  }
}

.author-info {
  padding: $b;
}

.inline-social {

  a {
    display: inline-block;
    margin-right: $b/2;

    &:hover {
      text-decoration: none;

      li {
        box-shadow: 0px 1px 2px rgba(20, 20, 20, 0.3);
        transform: translateY(-3px);
      }
    }

  }

  li {
    list-style: none;
    display: inline-block;
    background-color: white;
    padding: 5px;
    transition: 350ms all;
    box-shadow: 0px 1px 2px rgba(20, 20, 20, 0.2);

  }

  svg {
    display: inline-block;
    vertical-align: bottom;
  }

  svg path{
    fill: $blue;
  }
}


svg.logo-tech path {
  fill: $blue;
}

svg.logo-personal path{
  fill: $green;
}

svg.logo-research path {
  fill: $pink;
}

//LAYOUT
.col {
  margin-bottom: $b;
}

.layout-split-1-3 {
  margin: -$b 0;
  display: flex;

   & > .col:nth-child(1) {
    width: 33%;
  }

  & > .col:nth-child(2) {
    width: 66%;
  }

}

//ANIMATIONS: ;
@keyframes orbit1 {
  0%    { top: 0; left:0; z-index:2;}
  50%   { top: 10%; left: 100%; z-index:2;}
  51%   { top: 10%; left: 100%; z-index:0;}
  100%  { top: 0; left:0;  z-index:0;}
}


@keyframes orbit2 {
  0%    { top: 50%; left:101%; z-index:2;}
  50%   { top: 90%; left: 5%; z-index:2;}
  51%   { top: 90%; left: 5%; z-index:0;}
  100%  { top: 50%; left:101%;  z-index:0;}
}


@keyframes orbit3 {
  0%    { top: 5%;  left: 10%; z-index:2;}
  50%   { top: 95%; left: 75%; z-index:2;}
  51%   {  top: 95%; left: 75%; z-index:0;}
  100%  { top: 5%;  left: 10%;  z-index:0;}
}




//ANIMATIONS
@keyframes colorChange {
  0%   { background: #FF3D5A;  }
  25%  { background: lightskyblue; }
  50%  { background: yellowgreen; }
  75%  { background: lightskyblue; }
  100% { background: #FF3D5A;  }
}



// CV
.cv-header {
  display: flex;
  padding: $b;
  justify-content: space-between;
  // align-items: center;
}

.cv-name h2 {
  font-size: 40px;
  @include header-type(0px, bold);
  letter-spacing: 1;
  line-height: 55px;
  word-spacing: -15px;
  margin-bottom: 3px;

  b {
    color: $pink;
    // margin-right: -10px;
  }
}


.cv-info {
  text-align: right;

  li {
    list-style: none;

    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: $pink;
      border-radius: 100%;
      margin-left: $b;
      position: relative;
      top: 3px;
    }

    &:nth-child(2):after {
      background: $blue;
    }

    &:nth-child(3):after {
      background: $green;
    }
  }

}

.cv p, .cv li {
  font-size: 14px;
  line-height: 1.2;
}

.cv {
  h4 {
    font-size: 20px;
    margin-bottom: 6px;
  }

  h3 {
    font-size: 25px;
    margin-bottom: 6px;
  }

  p + h4, ul + h4, p + h3, p + ul {
    margin-top: $b*2;
  }

  h4 + p, h3 + p {
    margin-top:0;
  }




  .list--cv {
    // padding-left: 25px;

    li {
      list-style: none;

      &:before {
        content: "• ";
        color: $green;
        margin-right: 5px;
      }
    }
  }


}

.highlight--cv {
  color: $blue;
  font-weight: bold;
}

.cv-content {
  padding: $b;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  aside {
    width: 35%;
    padding-right: $b*2;
  }

  .cv-main {
    width: 65%;
    padding-left: $b*2;
    border-left: 5px solid black;
  }

  .cv-main + aside, aside + .cv-main {
    padding-top: $b*2;
  }

  aside + .cv-main:nth-child(2) {
    padding-top: 0;
  }
}

.list--condensed {
  li {
    list-style: none;
  }
}

@media print {
  .cv { /* Replace class_name with * to target all elements */
    -webkit-print-color-adjust: exact;
            print-color-adjust: exact; /* Non-Webkit Browsers */
  }




}
